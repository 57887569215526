import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import CryptoJS from "crypto-js";

const Contact = () => {
  const [formData, setFormData] = useState({
    ClientName: "",
    ClientPhone: "",
    ClientEmail: "",
    Subject: "",
    Message: "",
  });
  const [loading, setloading] = useState(false);
  const [message, setMessage] = useState("Send Message");
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const Api = process.env.REACT_APP_BASE_URL;
  const Key = process.env.REACT_APP_KEY;

  const validate = () => {
    let validationErrors = {};
    if (!formData.ClientName) {
      validationErrors.ClientName = "Name is required";
    }
    if (!formData.ClientPhone) {
      validationErrors.ClientPhone = "Phone is required";
    }
    if (!formData.ClientEmail) {
      validationErrors.ClientEmail = "Email is required";
    }
    if (!formData.Subject) {
      validationErrors.Subject = "Subject is required";
    }
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    setloading(true);

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setloading(false)
    } else {
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(formData),
        Key
      ).toString();
      try {
        setMessage("Sending, please wait")
        let res = await axios.post(Api, { encryptedData });
        if (res.status === 200) {
          setloading(false);
          setMessage("Message Sent");
          setloading(true)
          setErrors({})
        }
      } catch (err) {
        alert("Something went wrong, please reach out to us at +917006746013");
        setFormData({
          ClientName: "",
          ClientPhone: "",
          ClientEmail: "",
          Subject: "",
          Message: "",
        });
        setloading(false)
      }
    }

    setFormData({
      ClientName: "",
      ClientPhone: "",
      ClientEmail: "",
      Subject: "",
      Message: "",
    });
  };

  return (
    <>
    <Helmet>
        <title>Contact Halal Real Estate Pvt Ltd. | Reach Out to Us</title>

        <meta
          name="description"
          content="Get in touch with Halal Investments for expert guidance on ethical and Sharia-compliant investment opportunities. Contact us today to start your journey!"
        />
      </Helmet>
    <section className="contact">
      <div className="page-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-title">Contact</h1>
              <h2 className="page-description">Drop a Message to us</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-envelope"></i>
                    <h5>Mail</h5>
                    <h6>info@halalinvestments.co.in</h6>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-map-marker-alt"></i>
                    <h5>Address</h5>
                    <h6>Lawaypora Usmanabad Srinagar</h6>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-phone-alt"></i>
                    <h5>Phone</h5>
                    <h6>+917006746013</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row mt-5">
                <div className="col-lg-6">
                  <label>Name Surname</label>
                  <input
                    type="text"
                    name="ClientName"
                    value={formData.ClientName}
                    onChange={handleChange}
                    required
                    className="inp-contact"
                  />
                  {errors.ClientName && (
                    <span className="error">{errors.ClientName}</span>
                  )}
                </div>
                <div className="col-lg-6">
                  <label>Phone</label>
                  <input
                    type="text"
                    name="ClientPhone"
                    value={formData.ClientPhone}
                    onChange={handleChange}
                    required
                    className="inp-contact"
                  />
                  {errors.ClientPhone && (
                    <span className="error">{errors.ClientPhone}</span>
                  )}
                </div>
                <div className="col-lg-6">
                  <label>Email</label>
                  <input
                    type="text"
                    name="ClientEmail"
                    value={formData.ClientEmail}
                    onChange={handleChange}
                    required
                    className="inp-contact"
                  />
                  {errors.ClientEmail && (
                    <span className="error">{errors.ClientEmail}</span>
                  )}
                </div>
                <div className="col-lg-12">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="Subject"
                    value={formData.Subject}
                    onChange={handleChange}
                    required
                    className="inp-contact"
                  />
                  {errors.Subject && (
                    <span className="error">{errors.Subject}</span>
                  )}
                </div>
                <div className="col-lg-12">
                  <label>Message</label>
                  <textarea
                    type="text"
                    name="Message"
                    value={formData.Message}
                    onChange={handleChange}
                    className="ta-contact"
                    rows="4"
                  ></textarea>
                </div>
                <div className="col-lg-12">
                <button className="btn-contact" disabled={loading} onClick={handleSubmit}>
                      {message}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Contact;
