import FlatList from "./FlatList";
import Banner from "./Banner";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// import TeamList from "./TeamList"
// import References from "./References"
// import Subscribe from "./Subscribe"
import BestFlatList from "./BestFlatList";

const Home = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <React.Fragment>
      <Helmet>
        <title>Halal Real Estate Pvt Ltd. | Ethical and Sharia-Compliant Investment Solutions</title>

        <meta
          name="description"
          content="Discover ethical and Sharia-compliant investment opportunities with Halal Investments. Secure your financial future while staying true to your values."
        />
      </Helmet>
      <Banner />
      <FlatList />
      <BestFlatList />
      {/* <Subscribe/> */}
      {/* <TeamList/> */}
      {/* <References/> */}
    </React.Fragment>
  );
};

export default Home;
